import addElement from "./addElement";
import cloneArray from "./cloneArray";
import concatArray from "./concatArray";
import countCommon from "./countCommon";
import dictionary from "./dictionary";
import elementsPicker from "./elementsPicker";
import generateArray from "./generateArray";
import getIds from "./getIds";
import getOptions from "./getOptions";
import hasCommon from "./hasCommon";
import hasErrors from "./hasErrors";
import joinElements from "./joinElements";
import mergeObject from "./mergeObject";
import removeDuplicates from "./removeDuplicates";
import removeElement from "./removeElement";
import repeatComponent from "./repeatComponent";
import replaceElement from "./replaceElement";
import shuffleArray from "./shuffleArray";
import sortBy from "./sortBy";
import updateArray from "./updateArray";

const helpers = {
  addElement,
  cloneArray,
  concatArray,
  countCommon,
  dictionary,
  elementsPicker,
  generateArray,
  getIds,
  getOptions,
  hasCommon,
  hasErrors,
  joinElements,
  mergeObject,
  removeDuplicates,
  removeElement,
  repeatComponent,
  replaceElement,
  shuffleArray,
  sortBy,
  updateArray,
};

export default helpers;
