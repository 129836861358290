import { gql } from "@apollo/client";

const UPDATE_SETTINGS = gql`
  mutation UpdateUser(
    $userId: ID!
    $notificationSettings: NotificationSettingsInput
  ) {
    updateUser(userId: $userId, notificationSettings: $notificationSettings) {
      _id
      notificationSettings {
        eventReminder
        groupPost
        eventPost
        profilePost
      }
    }
  }
`;

export default UPDATE_SETTINGS;
