"use client";

import { useFormik } from "formik";
import { useCallback, useEffect } from "react";

/**
 * A custom hook for managing forms using Formik, with additional utilities.
 *
 * @param {object} options - Configuration options for the form.
 * @param {object} options.initialValues - Initial values for the form fields.
 * @param {function} options.onSubmit - A function to be called when the form is submitted.
 * @param {object} options.required - An object indicating which fields are required.
 * @param {function} options.validate - A custom validation function for the form.
 * @param {boolean} options.loading - A flag to control whether the form is in a loading state.
 * @returns {object} An object with Formik form state and additional utility functions.
 */
function useForm({ initialValues, onSubmit, required, validate, loading }) {
  // Create a Formik form instance with provided options
  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
    validateOnChange: false,
    validateOnBlur: true,
  });

  // Utility function to set form values
  const setValues = useCallback((data) => {
    formik.setValues((values) => ({
      ...values,
      ...data,
    }));
  }, []);

  // Synchronize form values with the provided initial values when 'loading' changes
  useEffect(() => {
    if (loading !== undefined) {
      if (!loading && Object.keys(initialValues).length) {
        setValues(initialValues);
      }
    }
  }, [loading, initialValues, setValues]);

  // Utility function to manage a stack of values in a field
  function setStack(name, value) {
    const target = formik.values[name];
    const index = target.findIndex((x) => x === value);

    setValues({
      [name]:
        index === -1
          ? [...target, value]
          : [...target.slice(0, index), ...target.slice(index + 1)],
    });
  }

  // Utility function to toggle a boolean field
  function toggle(name) {
    setValues({ [name]: !formik.values[name] });
  }

  return {
    ...formik, // Include Formik form state and functions
    required,
    setStack,
    setValues,
    toggle,
  };
}

export default useForm;
