/**
 * Add an element to an array if it doesn't already exist.
 *
 * @param {Array} array - The original array.
 * @param {any} element - The element to add to the array.
 * @returns {Array} A new array with the element added if it didn't exist in the original array.
 */
export default function addElement(array, element) {
  // Create a copy of the original array (or an empty array if it's null or undefined)
  const copy = array ? [...array] : [];

  // Check if the element doesn't already exist in the array
  if (!array.includes(element)) {
    // Add the element to the copy of the array
    copy.push(element);
  }

  return copy;
}
