import normalize from "./normalize";

/**
 * Generate a pluralized string based on the count.
 *
 * @param {number} count - The count used to determine pluralization.
 * @param {string} text - The singular form of the word.
 * @returns {string} The pluralized string based on the count.
 */
export default function pluralize(count, text) {
  if (typeof text !== "string") {
    return "";
  }

  if (count === 1) {
    return `${count} ${text}`;
  }

  // Apply common spanish pluralization rules
  if (
    text.endsWith("s") ||
    text.endsWith("n") ||
    text.endsWith("x") ||
    text.endsWith("z") ||
    text.endsWith("ch") ||
    text.endsWith("sh")
  ) {
    return `${count} ${normalize(text)}es`;
  }

  return `${count} ${text}s`;
}
