import { Workbox } from "workbox-window";

/**
 * A custom hook for registering and managing a service worker using Workbox.
 *
 * @returns {{
 *   isLocalhost: boolean,
 *   register: () => void
 * }} An object with properties and functions for managing service worker registration.
 */
function useWorkbox() {
  // Determine if the app is running on localhost
  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      window.location.hostname === "[::1]" ||
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  /**
   * Registers a service worker using Workbox for production environments.
   * It also handles service worker update and waiting events.
   */
  const register = () => {
    if (
      process.env.NODE_ENV === "production" &&
      "serviceWorker" in navigator &&
      !isLocalhost
    ) {
      // Create a new Workbox instance for the service worker file
      const wb = new Workbox("/scripts/service-worker.js");

      // Listen for the 'installed' event to reload the page if an update is detected
      wb.addEventListener("installed", (event) => {
        if (event.isUpdate) {
          window.location.reload();
        }
      });

      // Listen for the 'waiting' event to prompt the user to update the app
      wb.addEventListener("waiting", () => {
        if (confirm("A new version is available. Update now?")) {
          // Listen for the 'controlling' event to force a page reload after skipping waiting
          wb.addEventListener("controlling", () => {
            window.location.reload();
          });

          // Send a message to the service worker to skip waiting and activate the new version
          wb.messageSW({ type: "SKIP_WAITING" });
        }
      });

      // Register the service worker
      wb.register();
    }
  };

  return {
    isLocalhost,
    register,
  };
}

export default useWorkbox;
