import moment from "moment";

/**
 * Check if a date string is valid.
 *
 * @param {string} dateString - The date string to validate.
 * @returns {boolean} `true` if the date string is valid; otherwise, `false`.
 */
export default function isValidDate(dateString) {
  const date = moment(dateString, "YYYY-MM-DDTHH:mm:ss.SSSZ");
  return date.isValid();
}
