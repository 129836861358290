/**
 * Shuffle the elements of an array multiple times.
 *
 * @param {Array} array - The original array.
 * @param {number} times - The number of times to shuffle the array.
 * @returns {Array} A new array with elements shuffled multiple times.
 */
export default function shuffleArray(array = [], times = 10) {
  let res = [...array];

  for (let i = 0; i < times; i++) {
    res = res.sort(() => 0.5 - Math.random());
  }

  return res;
}
