import { gql } from "@apollo/client";

const GET_PAYMENT = gql`
  query GetPayment($id: String!) {
    getPayment(_id: $id) {
      _id
      status
    }
  }
`;

export default GET_PAYMENT;
