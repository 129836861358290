/**
 * Formats a numeric value as a currency string with a specified prefix.
 *
 * @param {number|string} [amount=0] - The numeric value to format as currency.
 * @param {string} [prefix="$"] - The currency symbol or prefix to prepend to the formatted value.
 * @returns {string} A currency string with the specified prefix.
 */
function currency(amount = 0, prefix = "$") {
  // Check if the amount is not provided or not a valid numeric value
  if (!amount || !parseFloat(amount)) {
    return prefix + "0"; // Return a default currency string
  }

  // Format the numeric value with thousands separators and a fixed number of decimal places
  const formattedAmount = parseFloat(amount)
    .toFixed(2) // Use two decimal places for cents
    .replace(/\d(?=(\d{3})+\.)/g, "$&.") // Add thousands separators
    .slice(0, -3); // Remove the decimal places (cents)

  // Combine the formatted amount with the specified prefix
  return prefix + formattedAmount;
}

/**
 * Function to detect the device type and display mode for a Progressive Web App (PWA).
 * It uses properties from window.navigator and navigator.userAgent to determine the device and display mode.
 *
 * @returns {{
 *   device: string,
 *   display: string
 * }} An object containing information about the detected device and display mode.
 */
function detectNavigator() {
  const display = window.navigator.standalone ? "standalone" : "browser";
  let device = "desktop";

  // Check for Android user agent to detect Android devices
  if (navigator.userAgent.match(/Android/i)) device = "android";
  // Check for iPhone, iPad, or iPod user agent to detect iOS devices
  else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) device = "ios";

  return {
    device,
    display,
  };
}

/**
 * Retrieves an HTML element by its unique ID.
 *
 * @param {string} id - The unique ID of the HTML element to retrieve.
 * @returns {HTMLElement | null} The HTML element with the specified ID, or null if not found.
 */
function getById(id) {
  return document.getElementById(id);
}

/**
 * Retrieves the vertical position (offset top) of an HTML element or a React ref's current node.
 * It adjusts the offset top by subtracting the height of the topbar (60 pixels) for positioning purposes.
 *
 * @param {string | React.RefObject} ref - The reference to the HTML element or React ref.
 * @returns {number} The adjusted vertical position (offset top) of the element, accounting for the topbar height (60 pixels).
 */
function getPosition(ref) {
  // Determine the node to calculate the offset top based on the input type
  const node = typeof ref === "string" ? getById(ref) : ref.current;

  // Calculate the adjusted offset top by subtracting the height of the topbar (60 pixels)
  return node?.offsetTop - 60;
}

/**
 * Opens a URL in a new browser window or tab.
 *
 * @param {string} url - The URL to open.
 * @param {number} time - The time (in milliseconds) to keep the window open. Use 0 to keep it open indefinitely.
 * @param {string} [target="_blank"] - The target attribute for the new window or tab. Defaults to "_blank" (opens in a new tab).
 */
function openUrl(url, time, target = "_blank") {
  // Open the URL in a new browser window or tab
  const win = window.open(url, target);

  if (win) {
    // Bring the newly opened window/tab to focus
    win.focus();

    if (time > 0) {
      // If a time limit is specified, close the window/tab after the specified time
      setInterval(() => win.close(), time);
    }
  }
}

/**
 * Scrolls the viewport to a specified HTML element's position with a smooth animation.
 *
 * @param {string | React.RefObject} ref - The reference to the HTML element or React ref to scroll to.
 * @param {number} [delay=0] - The delay (in milliseconds) before scrolling to the element.
 */
function scrollTo(ref, delay = 0) {
  // Calculate the vertical position to scroll to, accounting for any delay
  const top = ref ? getPosition(ref) : 0;

  // Scroll to the calculated position with a smooth animation
  window.scrollTo({ top: top + delay, behavior: "smooth" });
}

/**
 * Retrieves data from local storage (or another specified storage mechanism) using the provided name.
 * @param {String} name - The name or key used to retrieve data from storage.
 * @param {Object} [storage=localStorage] - The storage mechanism to use (default is localStorage).
 * @returns {Object|null} - The parsed data retrieved from storage, or null if the data is not found.
 */
function getItem(name, storage = localStorage) {
  return JSON.parse(storage.getItem(name));
}

/**
 * Removes an item from local storage by name.
 * @param {String} name - The name of the item to remove from local storage.
 * @param {Storage} storage - The storage object (e.g., localStorage or sessionStorage).
 */
function removeItem(name, storage = localStorage) {
  storage.removeItem(name);
}

/**
 * Sets an item in local storage with a specified name and data.
 * @param {String} name - The name of the item to set in local storage.
 * @param {any} data - The data to store in local storage (will be converted to JSON).
 * @param {Storage} storage - The storage object (e.g., localStorage or sessionStorage).
 */
function setItem(name, data, storage = localStorage) {
  storage.setItem(name, JSON.stringify(data));
}

/**
 * Delays program execution for the specified number of milliseconds.
 *
 * @param {number} ms - The number of milliseconds to sleep (pause) the program.
 * @returns {Promise} A promise that resolves after the specified sleep duration.
 */
/*function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}*/

import helper from "./helper";
import parser from "./parser";
import obj from "./object";
import str from "./string";

export {
  currency,
  detectNavigator,
  getById,
  getItem,
  getPosition,
  openUrl,
  removeItem,
  scrollTo,
  setItem,
  //sleep,
  helper,
  parser,
  obj,
  str,
};
