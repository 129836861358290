import { gql } from "@apollo/client";

const GET_TEMPLATES = gql`
  query GetNotificationTemplates($ids: [ID], $limit: Int, $offset: Int) {
    getNotificationTemplates(ids: $ids, limit: $limit, offset: $offset) {
      instances {
        _id
        body
      }
      offset
      limit
      total
    }
  }
`;

export default GET_TEMPLATES;
