import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

/**
 * Create and initialize a Firebase app.
 * @returns {Object} The Firebase app instance.
 */
export const createFirebaseApp = () => {
  const credentials = {
    apiKey: process.env.FIREBASE_API_KEY,
    appId: process.env.FIREBASE_APP_ID,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  };

  // Check if there are no existing Firebase apps initialized.
  if (getApps().length <= 0) {
    const app = initializeApp(credentials);

    // Initialize Firebase Analytics if measurementId is provided in credentials.
    if (typeof window !== "undefined") {
      if ("measurementId" in credentials) {
        getAnalytics(app);
      }
    }

    return app;
  }
};

// Create and export a Firebase app instance.
const app = createFirebaseApp();
export default app;
