const VERIFY_B = [
  {
    name: "address",
    verify: "addressInformation",
  },
  { name: "events", verify: "tutorial.event" },
  { name: "friends", verify: "tutorial.friend" },
  { name: "groups", verify: "tutorial.group" },
  { name: "posts", verify: "tutorial.post", skip: true },
  {
    name: "happiness",
    verify: "survey",
  },
  { name: "interests", verify: "interests" },
  {
    name: "personal",
    verify: "personalInformation",
  },
];

const VERIFY_F = [
  { name: "contact", verify: ["email", "phone"] },
  //{ name: "description", verify: ["biography"] },
  { name: "photoUrl", verify: ["hasProfilePic"] },
  { name: "subscription", verify: ["isPremium"] },
];

const STATUS = {
  pending: "pending",
  completed: "completed",
};

const METER = {
  verifyList: VERIFY_B,
  userInfo: VERIFY_F,
  status: STATUS,
};

export default METER;
