import toLocaleDate from "./toLocaleDate";

/**
 * Get the localized time (HH:mm) from a date string.
 *
 * @param {string} dateString - The date string to convert to localized time.
 * @returns {string} The localized time in "HH:mm" format.
 */
export default function getLocaleTime(dateString) {
  const dateTime = toLocaleDate(dateString);
  return dateTime.format("HH:mm");
}
