"use client";

import { Loading } from "components/system";
import { isLoading, getRefreshToken, isAuthenticated } from "store";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getItem, setItem, removeItem } from "utils";

export const useAuthGuard = () => {
  const isAuthed = isAuthenticated();
  const refreshToken = getRefreshToken();
  const loadingToken = isLoading();
  const pathname = usePathname();
  const router = useRouter();

  const [isMounted, setIsMounted] = useState(false);
  const [isCalled, setIsCalled] = useState(false);
  const isPrivate = pathname?.includes("/app");

  useEffect(() => {
    if (pathname) {
      if (isMounted) return;
      if (!refreshToken) setIsMounted(true);
      if (isPrivate && !refreshToken) {
        setItem("_bup.next", pathname);
        window.location.href = "/";
        setIsMounted(true);
      } else if (refreshToken) {
        setIsCalled(true);
      } else {
        setIsMounted(true);
      }
    }
  }, [isMounted, isPrivate, refreshToken, router]);

  useEffect(() => {
    if (isCalled && !loadingToken) {
      setTimeout(() => setIsMounted(true), 50);
      if (isPrivate && !isAuthed) {
        router.push("/");
      } else if (!isPrivate && isAuthed) {
        const next = getItem("_bup.next");
        router.push(next || "/app/overview");
        removeItem("_bup.next");
      }
    }
  }, [isPrivate, isAuthed, isCalled, loadingToken, router]);

  return isMounted;
};

export const withAuthGuard = (Component) => (props) => {
  const isMounted = useAuthGuard();
  return isMounted ? <Component {...props} /> : <Loading />;
};
