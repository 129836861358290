/**
 * Filter elements from an array with the same _id property.
 *
 * @param {Array} array - The original array.
 */
export default function removeDuplicates(array) {
  return array.filter(
    (element, index, self) =>
      index === self.findIndex((x) => x._id === element._id)
  );
}
