import moment from "moment";

/**
 * Convert a calendar date to a specific date format.
 *
 * @param {object} date - The calendar date object (e.g., from a library like moment.js).
 * @returns {string} The formatted date string (e.g., "YYYY-MM-DD").
 */
export default function calendarToDate(date) {
  return moment(date.$d).format("YYYY-MM-DD");
}
