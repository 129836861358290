import { gql } from "@apollo/client";

const COMMENT_FIELDS = gql`
  fragment CommentFields on Comment {
    _id
    createdAt
    from {
      _id
      info {
        firstName
        hasProfilePic
        lastName
      }
    }
    likes
    message
    updatedAt
  }
`;

export default COMMENT_FIELDS;
