import abbreviate from "./abbreviate";
import capitalize from "./capitalize";
import compare from "./compare";
import includes from "./includes";
import normalize from "./normalize";
import pluralize from "./pluralize";
import repeat from "./repeat";
import reverse from "./reverse";
import slugify from "./slugify";
import truncate from "./truncate";

const helpers = {
  abbreviate,
  capitalize,
  compare,
  includes,
  normalize,
  pluralize,
  repeat,
  reverse,
  slugify,
  truncate,
};

export default helpers;
