import { makeVar, useReactiveVar } from "@apollo/client";

export const userInfo = makeVar({
  biography: null,
  birth: null,
  city: null,
  civilStatus: null,
  commune: null,
  country: null,
  employmentSituation: null,
  firstName: null,
  gender: null,
  hasProfilePic: false,
  isAdmin: false,
  isPremium: false,
  lastName: null,
  middleName: null,
  phone: null,
  photoUrl: null,
  region: null,
  rut: null,
  surName: null,
});

export const userStore = makeVar({
  events: {
    going: [],
    hosting: [],
    interested: [],
    invites: [],
    history: [],
    suggest: [],
  },
  groups: {
    invites: [],
    joined: [],
    managed: [],
    suggest: [],
  },
  friends: [],
  interests: [],
  requests: {
    received: [],
    sent: [],
  },
  suggest: [],
  referral: {},
  status: {},
});

export const useUserInfo = () => {
  return useReactiveVar(userInfo);
};

export const useUserStore = () => {
  return useReactiveVar(userStore);
};
