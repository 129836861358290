import { gql } from "@apollo/client";

const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($subscriptionId: String!) {
    cancelSubscription(subscriptionId: $subscriptionId) {
      _id
    }
  }
`;

export default CANCEL_SUBSCRIPTION;
