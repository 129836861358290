import { gql } from "@apollo/client";

const UPDATE_USER_INTERESTS = gql`
  mutation UpdateUserInterest($id: ID!, $interest: ID!, $action: String!) {
    modifyUserInterest(_id: $id, interest: $interest, action: $action) {
      interests {
        _id
      }
    }
  }
`;

export default UPDATE_USER_INTERESTS;
