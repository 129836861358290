const EVENT = {
  required: [
    "capacity",
    "endTime",
    "feeType",
    "format",
    "isPublic",
    "name",
    "status",
    "startTime",
    "type",
  ],
  optional: [
    "address",
    "lat",
    "lng",
    "mapUrl",
    "meetUrl",
    "eventUrl",
    "photoUrl",
    "place",
    "placeId",
    "price",
  ],
  default: {
    accessList: [],
    accessType: "all",
    active: true,
    address: "",
    capacity: 0,
    category: "",
    commune: "",
    concurrency: "perEvent",
    dateList: [],
    description: "",
    endTime: undefined,
    eventUrl: "",
    feeType: "",
    format: "",
    instructor: "",
    interests: [],
    isPublic: null,
    mapUrl: "",
    meetUrl: "",
    multipleSession: false,
    name: "",
    photoUrl: "",
    place: "",
    price: 0,
    requiredAffiliation: [],
    requiredServiceTier: [],
    sessionDay: "",
    sessionTotal: 1,
    startTime: undefined,
    status: "review",
    streetNumber: "",
    streetRoute: "",
    type: "",
    _id: "",
  },
};

export default EVENT;
