/**
 * Truncate a text string to a maximum length, adding a suffix if truncated.
 *
 * @param {string} text - The text to truncate.
 * @param {number} maxLength - The maximum length of the truncated text.
 * @param {string} suffix - The suffix to add if the text is truncated.
 * @returns {string} The truncated text.
 */
export default function truncate(text, maxLength, suffix = "...") {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength - suffix.length) + suffix;
}
