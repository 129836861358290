import { gql } from "@apollo/client";

const ASSOCIATE_CARD = gql`
  mutation AssociateCard($id: ID!) {
    associateCard(_id: $id) {
      _id
      flow {
        associatedCard {
          associateCardUrl
        }
      }
    }
  }
`;

export default ASSOCIATE_CARD;
