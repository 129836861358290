import { DATE } from "consts";
import toLocaleDate from "./toLocaleDate";
import str from "utils/string";

/**
 * Format a full date string with optional inclusion of time.
 *
 * @param {string} dateString - The date string to format.
 * @param {boolean} includeTime - Whether to include the time in the formatted date.
 * @returns {string} The formatted full date string (e.g., "Sun 1 de January, 2023" or "Sun 1 de January, 2023 a las 15:30 hrs.").
 */
export default function formatFullDate(dateString, includeTime = false) {
  const date = toLocaleDate(dateString);
  const dayOfWeek = DATE.daysTranslations[date.format("dddd")]; //.slice(0, 3);
  const dayOfMonth = date.format("D");
  const month = DATE.monthTranslations[date.format("MMMM")];
  const year = date.format("YYYY");
  const time = includeTime ? ` a las ${date.format("HH:mm")} hrs.` : "";

  return `${str.capitalize(
    dayOfWeek
  )} ${dayOfMonth} de ${month}, ${year}${time}`;
}
