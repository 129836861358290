/**
 * Merge multiple objects into a single object, omitting null and undefined values.
 *
 * @param {...Object} args - Objects to merge.
 * @returns {Object} A merged object with null and undefined values omitted.
 */
export default function mergeObject(...args) {
  // Combine multiple objects into one
  const map = Object.assign({}, ...args);

  // Filter out keys with "__typename", null, and undefined values
  return Object.keys(map).reduce(
    (acc, key) =>
      key !== "__typename" && map[key] !== null && map[key] !== undefined
        ? { ...acc, [key]: map[key] }
        : acc,
    {}
  );
}
