import { gql } from "@apollo/client";

const READ_NOTIFICATION = gql`
  mutation MarkNotificationsAsRead($notificationIds: [ID]) {
    markNotificationsAsRead(notificationIds: $notificationIds) {
      status
    }
  }
`;

export default READ_NOTIFICATION;
