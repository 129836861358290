import { gql } from "@apollo/client";
import { EVENT_CORE } from "fragments";

const GET_EVENT_HISTORY = gql`
  query GetEventsHistory(
    $limit: Int
    $searchHistory: Boolean
    $dateFrom: String
    $dateUntil: String
  ) {
    networkGetEvents(
      limit: $limit
      searchHistory: $searchHistory
      dateFrom: $dateFrom
      dateUntil: $dateUntil
    ) {
      total
      instances {
        ...EventCore
      }
    }
  }
  ${EVENT_CORE}
`;

export default GET_EVENT_HISTORY;
