import { DATE } from "consts";
import moment from "moment";

/**
 * Format a birthday date string with optional inclusion of the year.
 *
 * @param {string} dateString - The birthday date string in "YYYY-MM-DD" format.
 * @param {boolean} includeYear - Whether to include the year in the formatted birthday.
 * @returns {string} The formatted birthday string.
 */
export default function formatBirthday(dateString, includeYear = false) {
  if (!dateString) {
    return null;
  }

  const date = moment(dateString, "YYYY-MM-DD");
  const day = date.format("D");
  const month = DATE.monthTranslations[date.format("MMMM")];
  const year = includeYear ? date.format("YYYY") : "";

  if (includeYear) {
    return `${day} de ${month} de ${year}`;
  } else {
    return `${day} de ${month}`;
  }
}
