/**
 * Convert a text string to a slug by replacing non-alphanumeric characters with hyphens.
 *
 * @param {string} text - The text to slugify.
 * @returns {string} The slugified text.
 */
export default function slugify(text) {
  return text
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+/g, "-")
    .replace(/(^-|-$)+/g, "");
}
