import { gql } from "@apollo/client";

const GET_EVENT_PICTURE = gql`
  query GetEventPicture($id: ID) {
    networkGetEvent(_id: $id) {
      _id
      photoUrl
      config {
        photoUrl
      }
    }
  }
`;

export default GET_EVENT_PICTURE;
