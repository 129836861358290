/**
 * Clone an array and add specified values to it if they don't already exist.
 *
 * @param {Array} array - The original array to clone.
 * @param {...any} values - Values to add to the cloned array.
 * @returns {Array} A new array with specified values added if they didn't exist in the original array.
 */
export default function cloneArray(array, ...values) {
  // Return an empty array if the original array is null or undefined
  if (!array) return [];

  // Create a copy of the original array
  const copy = [...array];

  // Iterate through the provided values and add them to the copy if they don't exist
  values?.forEach((element) => {
    if (!array?.includes(element)) {
      copy.push(element);
    }
  });

  return copy;
}
