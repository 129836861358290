import { gql } from "@apollo/client";
import { GROUP_CORE, PROFILE_CORE } from "fragments";

const GET_GROUP = gql`
  query GetGroup($id: String!) {
    getGroup(_id: $id) {
      ...GroupCore
      interests {
        _id
        icon
        name
      }
      members {
        ...ProfileCore
      }
      updatedAt
    }
  }
  ${GROUP_CORE}
  ${PROFILE_CORE}
`;

export default GET_GROUP;
