import { gql } from "@apollo/client";
import { GROUP_CORE } from "fragments";

const GET_GROUP_LIST = gql`
  query GetGroups($ids: [ID], $limit: Int) {
    getGroups(ids: $ids, limit: $limit) {
      total
      instances {
        ...GroupCore
      }
    }
  }
  ${GROUP_CORE}
`;

export default GET_GROUP_LIST;
