import moment from "moment";
import { DATE } from "consts";

/**
 * Get the astrological sign based on a date string.
 *
 * @param {string} dateString - The date string to determine the astrological sign for.
 * @returns {string} The astrological sign for the given date (e.g., "Aries").
 */
export default function getAstrological(dateString) {
  const parsedDate = moment(dateString);
  const timestamp = moment(`2023/${parsedDate.format("MM/DD")}`).valueOf();
  const calendar = DATE.astrologicalDate.map((day) =>
    moment(`2023/${day}`).valueOf()
  );
  const index = calendar.findIndex((limit) => timestamp <= limit);

  return DATE.astrologicalSign[index > 0 ? index : 0];
}
