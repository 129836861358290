import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

/**
 * Custom hook for using Apollo's useMutation with BUP's recommended setup.
 * @param {gql} mutation - The GraphQL mutation to run.
 * @param {Object} variables - The mutation variables to use.
 * @param {string} policy - The fetch policy to use (default is 'cache-and-network').
 * @returns {array} - An array containing the onSubmit function and the mutation response object.
 */
function useBupMutation(mutation) {
  // Call useMutation with the provided mutation and get the trigger function and mutation response object.
  const [trigger, { called, data, loading, error }] = useMutation(mutation);
  // Create local state for the response object and a flag for whether the mutation has loaded.
  const [response, setResponse] = useState({});
  const [loaded, setLoaded] = useState(false);

  // Use useEffect to update the response state and set the loaded flag when the mutation finishes loading.
  useEffect(() => {
    if (!loading && data) {
      // The response data is an object with a single key containing the actual data we want to use.
      // Use Object.values() to extract the data object, and then get the first item in the array ([0]).
      setResponse(Object.values(data)?.[0]);
      setLoaded(true);
    }
  }, [data, loading, trigger, mutation]);

  // Define the onSubmit function that triggers the mutation and handles errors
  const onSubmit = async (variables, options = {}) => {
    try {
      return await trigger({ variables, ...options });
    } catch (error) {
      console.log("error", error);
    }
  };

  // Return an array containing the onSubmit function and the mutation response object.
  return [onSubmit, { called, data: response, loading, loaded, error }];
}

export default useBupMutation;
