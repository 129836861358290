import { DATE } from "consts";

/**
 * Get the difference in hours between a given date and the current date.
 *
 * @param {string} date - The date string to compare.
 * @returns {number} The difference in hours.
 */
export default function getDiffHours(date) {
  const diffTime = Math.abs(new Date() - new Date(date));
  return Math.ceil(diffTime / DATE.hourInMilliseconds);
}
