import { makeVar, useReactiveVar } from "@apollo/client";

export const networkStore = makeVar({
  admins: [],
  ambassadors: [],
  events: [],
  groups: [],
  history: [],
  interests: [],
  people: [],
  pictures: {},
});

export const useNetwork = () => {
  return useReactiveVar(networkStore);
};
