import { authStore } from "./model";
import { getItem } from "utils";

export const getRefreshToken = () => {
  try {
    if (typeof window === "undefined") return null;
    return getItem("_bup.rt");
  } catch (error) {
    console.error("Error retrieving refresh token:", error);
    return null;
  }
};

export const getExpirationDate = () => {
  try {
    if (typeof window === "undefined") return null;
    return getItem("_bup.exp");
  } catch (error) {
    console.error("Error retrieving expiration date:", error);
    return null;
  }
};

export const isExpired = () => {
  const date = getExpirationDate();
  if (date) {
    return new Date().getTime() > parseInt(date, 10);
  }
  return true;
};

export const getAuthStore = () => {
  return authStore();
};

export const isAdmin = () => {
  return authStore().role === "admin";
};

export const isAuthenticated = () => {
  return authStore().accessToken !== null;
};
