"use client";

import { ApolloProvider } from "@apollo/client";
import { useDarkMode, useFormik, useMedia } from "hooks";
import { createContext, useContext, useMemo } from "react";
import { createApolloClient } from "session/apolloClient";
import usePWAInstall from "session/usePWAInstall";
import { updateNetwork, useNetwork as useAppStore } from "store";
import useInterests from "./useInterests";

const ConfigContext = createContext();
const ConsoleContext = createContext();
const NetworkContext = createContext();

const NetworkProvider = ({ children, config }) => {
  const client = createApolloClient();

  return (
    <ApolloProvider client={client}>
      <ConfigContext.Provider
        value={useMemo(() => Object.assign({}, config), [config])}
      >
        <Wrapper>{children}</Wrapper>
      </ConfigContext.Provider>
    </ApolloProvider>
  );
};

const Wrapper = ({ children }) => {
  const cpanel = useFormik({ initialValues });
  const { dictionary, ...interests } = useInterests();
  const progressiveWebApp = usePWAInstall();
  const darkMode = useDarkMode();
  const network = useAppStore();
  const screen = useMedia();

  return (
    <ConsoleContext.Provider
      value={useMemo(
        () =>
          Object.assign({}, progressiveWebApp, {
            interests: dictionary,
            isLoading: false,
            isOnline: true,
            darkMode,
            cpanel,
            screen,
          }),
        [progressiveWebApp, dictionary, cpanel]
      )}
    >
      <NetworkContext.Provider
        value={useMemo(
          () =>
            Object.assign({}, network, interests, {
              updateNetwork,
            }),
          [network, interests]
        )}
      >
        {children}
      </NetworkContext.Provider>
    </ConsoleContext.Provider>
  );
};

const initialValues = {
  expanded: true,
  menu: false,
  meter: false,
  modal: false,
  query: "",
  search: false,
  tab: 0,
  tools: false,
  view: 0,
};

const useConfig = () => useContext(ConfigContext) || {};
const useConsole = () => useContext(ConsoleContext) || {};
const useNetwork = () => useContext(NetworkContext) || {};

export { NetworkProvider, useConfig, useConsole, useNetwork };
