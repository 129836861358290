import { gql } from "@apollo/client";

const REFRESH_ACCESS_TOKEN = gql`
  query RefreshAccessToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

export default REFRESH_ACCESS_TOKEN;
