import GET_ADMINS from "./getAdmins";
import GET_AMBASSADORS from "./getAmbassadors";
import GET_FRIEND from "./getFriend";
import GET_FRIEND_LIST from "./getFriendList";
import GET_FRIEND_SUGGEST from "./getFriendSuggest";

export {
  GET_ADMINS,
  GET_AMBASSADORS,
  GET_FRIEND,
  GET_FRIEND_LIST,
  GET_FRIEND_SUGGEST,
};
