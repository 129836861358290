"use client";

import { useEffect, useState } from "react";

/**
 * Custom React hook for handling Progressive Web App (PWA) installation.
 * This hook listens for the "beforeinstallprompt" event and provides a function
 * to trigger PWA installation.
 *
 * @returns {{
 *   isReadyForInstall: boolean,
 *   downloadPWA: () => void
 * }} An object containing the state and function for PWA installation.
 */
function usePWAInstall() {
  // State to track whether the PWA is ready for installation
  const [isReadyForInstall, setIsReadyForInstall] = useState(false);
  const [isInstalling, setIsInstalling] = useState(false);

  useEffect(() => {
    // Event listener for the "beforeinstallprompt" event
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      window.deferredPrompt = event;
      setIsReadyForInstall(true);
    });
  }, []);

  /**
   * Initiates the installation of the PWA.
   */
  async function downloadPWA() {
    const promptEvent = window.deferredPrompt;
    setIsInstalling(true);
    if (!promptEvent) return;
    promptEvent.prompt();
    await promptEvent.userChoice;
    window.deferredPrompt = null;
    setIsReadyForInstall(false);
  }

  return {
    isReadyForInstall,
    isInstalling,
    downloadPWA,
  };
}

export default usePWAInstall;
