import { gql } from "@apollo/client";

const GET_SUBSCRIPTION = gql`
  query GetCurrentUserSubscription($id: String!) {
    getCurrentUserSubscription(_id: $id) {
      _id
      morose
      planId
      status
      payments {
        _id
        paymentUrl
        status
      }
    }
  }
`;

export default GET_SUBSCRIPTION;
