import { gql } from "@apollo/client";

const POST_SIGNIN = gql`
  query SignIn($email: String!, $password: String!, $app: String) {
    signin(email: $email, password: $password, app: $app) {
      accessToken
      refreshToken
    }
  }
`;

export default POST_SIGNIN;
