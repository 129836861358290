"use client";

import { useState, useEffect } from "react";
import firebaseApp from "./firebaseApp";
import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";

/**
 * Asynchronously adds a document to a Firestore collection.
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {Object} docData - The data to be added to the document.
 * @returns {Promise<Object>} A promise that resolves to the added document with its ID.
 */
async function addDocument(collectionName, docData) {
  let doc = null;

  try {
    const db = getFirestore(firebaseApp);
    doc = await addDoc(collection(db, collectionName), docData);
  } catch (error) {
    console.log(error);
  }

  return Object.assign({}, docData, {
    id: doc.id,
  });
}

/**
 * Asynchronously deletes a document from a Firestore collection.
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {string} docId - The ID of the document to be deleted.
 */
async function deleteDocument(collectionName, docId) {
  const db = getFirestore();
  const docRef = doc(db, collectionName, docId);
  await deleteDoc(docRef);
}

/**
 * Retrieves a collection of documents from Firestore, optionally in real-time.
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {boolean} isRealtime - Set to true to enable real-time updates.
 * @returns {Array} An array of documents from the Firestore collection.
 */
function useCollection(collectionName, isRealtime) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (!isRealtime) {
        const db = getFirestore(firebaseApp);
        const snap = await getDocs(collection(db, collectionName));

        const docs = snap.docs.map((doc) => {
          const data = { ...doc.data(), id: doc.id };
          if (collectionName !== "config") data.ref = collectionName;
          return data;
        });

        setData(docs);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (isRealtime) {
      const db = getFirestore(firebaseApp);
      const unsubscriber = onSnapshot(
        collection(db, collectionName),
        (snap) => {
          const docs = snap.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            ref: collectionName,
          }));
          setData(docs);
        }
      );

      return () => {
        unsubscriber();
      };
    }
  }, [isRealtime]);

  return data;
}

/**
 * Asynchronously retrieves a document from a Firestore collection.
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {string} docId - The ID of the document to be retrieved.
 * @returns {Promise<Object>} A promise that resolves to the retrieved document.
 */
async function getDocument(collectionName, docId) {
  const db = getFirestore(firebaseApp);
  const docRef = doc(db, collectionName, docId);
  return await getDoc(docRef);
}

/**
 * Asynchronously sets the data of a Firestore document.
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {string} docId - The ID of the document to be updated.
 * @param {Object} docData - The data to be set for the document.
 */
async function setDocument(collectionName, docId, docData) {
  const db = getFirestore(firebaseApp);
  const docRef = doc(db, collectionName, docId);
  await setDoc(docRef, docData);
}

/**
 * Asynchronously updates the data of a Firestore document.
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {string} docId - The ID of the document to be updated.
 * @param {Object} docData - The data to be updated for the document.
 */
async function updateDocument(collectionName, docId, docData) {
  const db = getFirestore(firebaseApp);
  const docRef = doc(db, collectionName, docId);
  await updateDoc(docRef, docData);
}

export {
  addDocument,
  deleteDocument,
  deleteField,
  getDocument,
  setDocument,
  updateDocument,
  useCollection,
};
