"use client";

import { useTrigger } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { getItem, setItem } from "utils";
import helper from "utils/helper";
import useSWR from "swr";

const MODE = { dark: "dark", light: "light" };
const ID = "_bup-mode";

/**
 * toggleMode
 * @param {String} currentMode
 * @description
 * This function is used to toggle the current mode between dark and light.
 */
function toggleMode(currentMode) {
  return currentMode === MODE.dark ? MODE.light : MODE.dark;
}

/**
 * useDarkMode
 * @description
 * This hook is used to manage the dark mode state.
 */
function useDarkMode() {
  const [called, setCalled] = useState(false);
  const { data } = useSWR(
    ID,
    (key) => getItem(key) || MODE.light
    /*(window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
        ? MODE.dark
        : MODE.light)*/
  );

  const [theme, setTheme] = useState(data);
  const isDark = theme === MODE.dark;

  useEffect(() => {
    if (data && data !== theme && !called) {
      setCalled(true);
      setTheme(data);
    }
  }, [data]);

  useEffect(() => {
    if (called) {
      document.documentElement.setAttribute("data-theme", theme);
      helper.updateClassList("bup-" + MODE.dark, isDark);
      setItem(ID, theme);
    }
  }, [called, theme, isDark]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.ctrlKey && e.key === "m") {
        e.preventDefault();
        setTheme(toggleMode(theme));
      }
    },
    [theme]
  );

  const toggle = useCallback(() => setTheme(toggleMode(theme)), [theme]);

  useTrigger("keydown", handleKeyPress);

  return {
    isDark,
    theme,
    icon: `${toggleMode(theme)}_mode`,
    toggle,
  };
}

export default useDarkMode;
