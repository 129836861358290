import { userInfo, userStore } from "./model";

export const getUserInfo = () => {
  return userInfo();
};

export const getUserStore = () => {
  return userStore();
};

export const getIsPremium = () => {
  return userInfo().isPremium;
};

export const getPhotoUrl = () => {
  return userInfo().photoUrl;
};
