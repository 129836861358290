"use client";

import { useQuery } from "gql";
import PropTypes from "prop-types";
import { GET_FRIEND, GET_GROUP } from "queries/community";
import { GET_EVENT } from "queries/events";
import { useMemo } from "react";

function useDocument(collectionName, docId) {
  const { data, loading } = useQuery(
    useMemo(
      () =>
        ({
          events: GET_EVENT,
          friends: GET_FRIEND,
          groups: GET_GROUP,
        })[collectionName],
      [collectionName]
    ),
    { id: docId }
  );

  return [data, loading];
}

useDocument.propTypes = {
  collectionName: PropTypes.oneOf(["events", "friends", "groups"]).isRequired,
  docId: PropTypes.string.isRequired,
};

export default useDocument;
