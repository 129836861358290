import { gql } from "@apollo/client";

const GET_USER_INFO = gql`
  query GetUserInfo($id: String!) {
    getUserProfile(_id: $id) {
      biography
      info {
        addressAdditional
        addressNumber
        addressStreet
        birth
        civilStatus
        commune
        country
        email
        employmentSituation
        firstName
        gender
        lastName
        middleName
        phone
        region
        rut
        surName
      }
    }
  }
`;

export default GET_USER_INFO;
