import { gql } from "@apollo/client";
import { PROFILE_CORE } from "fragments";

const GET_AMBASSADORS = gql`
  query GetAmbassadors(
    $limit: Int
    $affiliation: String
    $isSameCommune: Boolean
    $offset: Int
  ) {
    getUsersProfiles(
      limit: $limit
      affiliation: $affiliation
      isSameCommune: $isSameCommune
      offset: $offset
    ) {
      total
      instances {
        ...ProfileCore
      }
    }
  }
  ${PROFILE_CORE}
`;

export default GET_AMBASSADORS;
