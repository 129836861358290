import { gql } from "@apollo/client";
import { PROFILE_CORE } from "fragments";

const GET_FRIEND = gql`
  query GetFriend($id: String!) {
    getUserProfile(_id: $id) {
      ...ProfileCore
      biography
      createdAt
      info {
        addressNumber
        addressStreet
        birth
        commune
        country
        email
        firstName
        gender
        hasProfilePic
        lastName
        phone
        region
        rut
      }
      interests {
        _id
        icon
        name
      }
      events {
        going {
          _id
        }
      }
      friends {
        list {
          _id
        }
      }
      groups {
        joined {
          _id
        }
      }
    }
  }
  ${PROFILE_CORE}
`;

export default GET_FRIEND;
