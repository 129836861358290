import moment from "moment";

/**
 * Get an array of calendar days for a given month.
 *
 * @param {string} month - The month in "YYYY-MM" format.
 * @returns {Array} An array containing the provided month and an array of calendar days in "YYYY-MM-DD" format.
 */
export default function getCalendarDays(month) {
  const firstDayOfMonth = moment(month).startOf("month");
  const startDate = firstDayOfMonth.clone().startOf("week");
  const endDate = moment(startDate).add(5, "weeks").endOf("week");
  const currentMonth = firstDayOfMonth.format("YYYY-MM");

  const calendarDays = [];
  let currentDate = startDate;

  while (currentDate.isSameOrBefore(endDate)) {
    calendarDays.push(currentDate.format("YYYY-MM-DD"));
    currentDate.add(1, "day");
  }

  return [currentMonth, calendarDays];
}
