import Box from "@mui/material/Box";
import Head from "next/head";
import Logo from "./Logo";

function Loading() {
  return (
    <Box className="BupMain-loading">
      <Head>
        <title>Cargando...</title>
      </Head>
      <Logo />
    </Box>
  );
}

export default Loading;
