import { gql } from "@apollo/client";

const DELETE_SUBSCRIPTION = gql`
  mutation EndSubscription($subscriptionId: String!) {
    endSubscription(subscriptionId: $subscriptionId) {
      _id
    }
  }
`;

export default DELETE_SUBSCRIPTION;
