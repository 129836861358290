import { gql } from "@apollo/client";
import { EVENT_CORE } from "fragments";

const GET_EVENT_LIST = gql`
  query GetEvents(
    $ids: [ID]
    $config: ID
    $limit: Int
    $status: String
    $searchHistory: Boolean
  ) {
    networkGetEvents(
      ids: $ids
      config: $config
      limit: $limit
      status: $status
      searchHistory: $searchHistory
    ) {
      total
      instances {
        ...EventCore
      }
    }
  }
  ${EVENT_CORE}
`;

export default GET_EVENT_LIST;
