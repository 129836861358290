import { gql } from "@apollo/client";

const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile(
    $id: ID!
    $biography: String
    $info: ModifyUserProfileInfoInput
  ) {
    modifyUserProfile(_id: $id, biography: $biography, info: $info) {
      _id
    }
  }
`;

export default UPDATE_USER_PROFILE;
