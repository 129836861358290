import normalize from "./normalize";

/**
 * Compare two strings after normalizing them.
 *
 * @param {string} target - The target string for comparison.
 * @param {string} value - The value to compare against.
 * @returns {boolean} True if the normalized target and value are equal; otherwise, false.
 */
export default function compare(target, value) {
  if (!target || !value) {
    return false;
  }

  // Normalize both the target and value for comparison
  const normalizedTarget = normalize(target);
  const normalizedValue = normalize(value);

  return normalizedTarget === normalizedValue;
}
