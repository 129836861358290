/**
 * Check if two arrays have common elements.
 *
 * @param {Array} arr1 - The first array.
 * @param {Array} arr2 - The second array.
 * @returns {boolean} `true` if the arrays have common elements, otherwise `false`.
 */
export default function hasCommon(arr1 = [], arr2 = []) {
  let common = false;

  // Check if both arrays have elements
  if (arr1?.length && arr2?.length) {
    // Iterate through the first array and check for common elements in the second array
    for (let i = 0; i < arr1.length; i++) {
      if (arr2.includes(arr1[i])) {
        common = true;
        break;
      }
    }
  }

  return common;
}
