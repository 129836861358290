import { gql } from "@apollo/client";

const GET_USER_STORE = gql`
  query GetUserStore($id: String!) {
    getUserProfile(_id: $id) {
      _id
      events {
        going {
          _id
        }
        interested {
          _id
        }
        invites {
          userId {
            _id
          }
          eventId {
            _id
          }
        }
        past {
          _id
        }
      }
      friends {
        list {
          _id
        }
        receivedRequest {
          _id
        }
        sentRequest {
          _id
        }
      }
      groups {
        joined {
          _id
        }
        invites {
          _id
        }
      }
      interests {
        _id
      }
      referralDetails {
        availablePeriods
        redeemedPeriods
        referralCode
        referredUsers
      }
      status {
        onboardingCompleted
      }
    }
  }
`;

export default GET_USER_STORE;
