import { gql } from "@apollo/client";
import { COMMENT_FIELDS, POST_FIELDS } from "fragments";

const GET_USER_FEED = gql`
  query GetUserFeed($limit: Int, $offset: Int) {
    generateFeed(limit: $limit, offset: $offset) {
      total
      instances {
        ...PostFields
        comments {
          ...CommentFields
        }
        to
      }
    }
  }
  ${COMMENT_FIELDS}
  ${POST_FIELDS}
`;

export default GET_USER_FEED;
